import useTranslation from 'next-translate/useTranslation'
import React from 'react'
import CountUp from 'react-countup'

import {
  IconChart,
  IconDiploma,
  IconHandStars,
  IconTwoRounded,
} from '@/res/icons'
import { ImageAbout } from '@/res/images'
import { NAMESPACE_LANDING_PAGE } from '@/res/namespaces'

import LandingContainer from '../LandingContainer'

const About = () => {
  const { t } = useTranslation(NAMESPACE_LANDING_PAGE)

  return (
    <div id="about" className="bg-[#F6F7F8] tablet:px-0 small:px-16 px-0 ">
      <LandingContainer>
        <div>
          <p className="desktop:text-[44px] small:pt-[100px] pt-[50px] uppercase desktop:leading-[48px] tablet:text-[38px] tablet:leading-[44px] small:text-[34px] small:leading-[40px] text-[30px] leading-[36px] font-bold text-black/80">
            {t('about.about_us')}
          </p>
          <div className="text-black/80 grid laptop:grid-cols-4 small:grid-cols-2 grid-cols-1 gap-5 mt-5 ">
            <div
              style={{
                backgroundImage: `url(${ImageAbout.src})`,
              }}
              className="bg-no-repeat  bg-cover rounded-2xl    h-full hover:scale-105 hover:z-10 transition-all "
            >
              <div className="flex flex-col justify-between h-[300px]">
                <div className="laptop:w-14 laptop:h-14 tablet:w-20 tablet:h-20 small:w-14 small:h-14 little:w-20 little:h-20  w-14 h-14 bg-white rounded-2xl">
                  <IconTwoRounded className="w-12 h-12 laptop:m-4 tablet:m-7  small:m-4 little:m-7 m-4" />
                </div>
                <div className="p-4 w-[211px]">
                  <span className="text-xl text-[#0065E5] font-bold">
                    {t('about.skilled_professionals')}
                  </span>
                  <p className="font-semibold mt-[13px] text-5xl">
                    <CountUp end={200} duration={4} />
                    <sup>+</sup>
                  </p>
                </div>
              </div>
            </div>
            <div
              style={{
                backgroundImage: `url(${ImageAbout.src})`,
              }}
              className="bg-no-repeat  bg-cover rounded-2xl    h-full hover:scale-105 hover:z-10 transition-all "
            >
              <div className="flex flex-col justify-between h-[300px]">
                <div className="laptop:w-14 laptop:h-14 tablet:w-20 tablet:h-20 small:w-14 small:h-14 little:w-20 little:h-20  w-14 h-14 bg-white rounded-2xl">
                  <IconDiploma className="w-12 h-12 laptop:m-4 tablet:m-7  small:m-4 little:m-7 m-4" />
                </div>
                <div className="p-4 w-[211px]">
                  <span className="text-xl text-[#0065E5] font-bold">
                    {t('about.products_delivered')}
                  </span>
                  <p className="font-semibold mt-[13px] text-5xl">
                    <CountUp end={60} duration={4} />
                    <sup>+</sup>
                  </p>
                </div>
              </div>
            </div>
            <div
              style={{
                backgroundImage: `url(${ImageAbout.src})`,
              }}
              className="bg-no-repeat  bg-cover rounded-2xl    h-full hover:scale-105 hover:z-10 transition-all "
            >
              <div className="flex flex-col justify-between h-[300px]">
                <div className="laptop:w-14 laptop:h-14 tablet:w-20 tablet:h-20 small:w-14 small:h-14 little:w-20 little:h-20  w-14 h-14 bg-white rounded-2xl">
                  <IconChart className="w-12 h-12 laptop:m-4 tablet:m-7  small:m-4 little:m-7 m-4" />
                </div>
                <div className="p-4 w-[211px]">
                  <span className="text-xl text-[#0065E5] font-bold ">
                    {t('about.on_the_market')}
                  </span>
                  <p className="font-semibold mt-[13px] text-5xl flex">
                    <CountUp end={12} duration={4} />
                    <sup className="text-[20px] font-bold pt-[17px] ">
                      {t('about.years')}
                    </sup>
                  </p>
                </div>
              </div>
            </div>
            <div
              style={{
                backgroundImage: `url(${ImageAbout.src})`,
              }}
              className="bg-no-repeat  bg-cover rounded-2xl   h-full hover:scale-105 hover:z-10 transition-all "
            >
              <div className="flex flex-col justify-between h-[300px]">
                <div className="laptop:w-14 laptop:h-14 tablet:w-20 tablet:h-20 small:w-14 small:h-14 little:w-20 little:h-20  w-14 h-14 bg-white rounded-2xl">
                  <IconHandStars className="w-12 h-12 laptop:m-4 tablet:m-7  small:m-4 little:m-7 m-4" />
                </div>
                <div className="p-4 w-[211px]">
                  <span className="  text-xl text-[#0065E5] font-bold">
                    {t('about.clients_sotifiction')}
                  </span>
                  <p className="font-semibold mt-[13px] text-5xl flex">
                    <CountUp end={99} duration={4} />
                    <sup className="text-[20px] font-bold pt-[20px]">%</sup>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LandingContainer>
    </div>
  )
}

export default About
