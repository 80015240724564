import useTranslation from 'next-translate/useTranslation'
import React, { useState } from 'react'

import ImageBgLanding from '@/assets/images/bg-landing.png'
import ParticlesNightSky from '@/components/ui/Particles'
import { ImageMain, ImageMain2, ImageMain3 } from '@/res/images'
import { NAMESPACE_LANDING_PAGE } from '@/res/namespaces'

import LandingNavbar from './Navbar'

const Main = () => {
  const { t } = useTranslation(NAMESPACE_LANDING_PAGE)
  const [hamburgerMenu, setHamburgerMenu] = useState<any>(false)

  return (
    <div
      style={{
        backgroundImage: `url(${ImageBgLanding.src})`,
      }}
      className="min-h-[calc(100vh)] bg-no-repeat  bg-cover flex text-white"
    >
      {hamburgerMenu ? (
        <div className="fixed inset-0 z-10 w-screen h-screen backdrop-blur-xl "></div>
      ) : null}
      <ParticlesNightSky />
      <div className="relative flex-col flex-1 text-center">
        <LandingNavbar
          hamburgerMenu={hamburgerMenu}
          setHamburgerMenu={setHamburgerMenu}
        />
        <div
          style={{
            backgroundImage: `url(${ImageMain.src})`,
          }}
          className="laptop:block hidden max-w-[878px] h-[184px] mx-auto tablet:mt-20 mt-64 bg-no-repeat  bg-cover"
        >
          <p className=" pt-2 laptop:text-[56px] text-center w-[878px] tablet:text-[36px] text-[#0065E5]  tablet:leading-[55px] font-bold uppercase">
            {t('seo_description')}
          </p>
          <p className="pl-[70px] desktop:text-[34px]  text-center w-[800px] select-none tablet:text-[32px]   tablet:leading-[80px] font-bold uppercase">
            {t('title')}
          </p>
        </div>
        <div
          style={{
            backgroundImage: `url(${ImageMain2.src})`,
          }}
          className=" laptop:hidden small:block  hidden max-w-[508px]  mx-auto  mt-44 bg-no-repeat  "
        >
          <p className=" select-none  text-[#0065E5] tablet:text-[20px] small:text-[20px] small:leading-[65px] leading-[45px] font-bold uppercase">
            {t('seo_description')}
          </p>
          <p className=" select-none tablet:text-[20px]  small:text-[20px] small:leading-[56px] leading-[45px] font-bold uppercase">
            {t('title')}
          </p>
        </div>
        <div
          style={{
            backgroundImage: `url(${ImageMain3.src})`,
          }}
          className=" small:hidden max-w-[345px]  mx-auto text-center  mt-44 bg-no-repeat  "
        >
          <p className=" select-none  text-[#0065E5] text-[13px] leading-[40px] font-bold uppercase">
            {t('seo_description')}
          </p>
          <p className=" pl-2 select-none  text-[13px] text-center  leading-[40px] font-bold uppercase">
            {t('title')}
          </p>
        </div>
      </div>
    </div>
  )
}

export default Main
