
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import type { GetStaticProps, NextPage } from 'next'
import useTranslation from 'next-translate/useTranslation'
import { ToastContainer } from 'react-toastify'

import { getServices } from '@/api/services/getServices'
import About from '@/components/landing/about/Abaout'
import Clients from '@/components/landing/clients/Clients'
import Footer from '@/components/landing/footer/Footer'
import MainSection from '@/components/landing/mainSection/MainSection'
import Projects from '@/components/landing/projects/Projects'
import Request from '@/components/landing/request/Request'
import Services from '@/components/landing/services/Services'
import Tools from '@/components/landing/tools/tools'
import BaseLayout from '@/components/layouts/BaseLayout'
import NewsList from '@/components/news/NewsList'
import { NAMESPACE_LANDING_PAGE } from '@/res/namespaces'
import { IService } from '@/types/Basic'

interface Props {
  plans: IService[]
}

const Home: NextPage<Props> = ({ plans }) => {
  const { t } = useTranslation(NAMESPACE_LANDING_PAGE)
  return (
    <BaseLayout
      additionalMetaTags={[
        {
          content: keywords.join(','),
          name: 'keywords',
        },
      ]}
      seoTitle={t('seo_title')}
      seoDescription={t('seo_description')}
    >
      <div className="landing scroll-smooth">
        <ToastContainer />
        <MainSection />
        <Services plans={plans} />
        <About />
        <Clients />
        <Tools />
        <Projects />
        <Request />
        <NewsList />
        <Footer />
      </div>
    </BaseLayout>
  )
}

 const _getStaticProps: GetStaticProps = async ({ locale }) => {
  const res = await getServices(locale as string)

  return {
    props: {
      plans: res.results,
    },
    revalidate: 100,
  }
}

const keywords = [
  'Uztelecom',
  'Облачные услуги',
  'Cloud services',
  'Bulutli xizmatlar',
  'Uz cloud',
  'Cloud uz',
  'Разработка uz',
  'Оформить заявку',
  'Облачная платформа',
  'технологии Uztelecom',
  'Облачные решения',
  'ИТ решения',
  'ЦОД uz',
  'Физические сервера',
  'Data center uz',
  'Data sentr uz',
  'Uztelecom xizmatlari',
  'Domen uz',
  'Домен уз',
]

export default Home


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        let res = _getStaticProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  