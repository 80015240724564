import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'
import React from 'react'

import { ImageService } from '@/res/images'
import { NAMESPACE_LANDING_PAGE } from '@/res/namespaces'
import { ROUTE_SERVICES } from '@/res/routes'
import { IService } from '@/types/Basic'

import LandingContainer from '../LandingContainer'

interface Props {
  plans: IService[]
}
const Services: React.FC<Props> = ({ plans }) => {
  const { t } = useTranslation(NAMESPACE_LANDING_PAGE)

  return (
    <div id="services" className="bg-[#F6F7F8] tablet:px-0 small:px-16 px-0 ">
      <LandingContainer>
        <div>
          <p className="small:pt-[100px] pt-[50px] desktop:text-[44px] desktop:leading-[48px] tablet:text-[38px] tablet:leading-[44px] small:text-[34px] small:leading-[40px] text-[30px] leading-[36px] font-bold text-black/80 uppercase">
            {t('services.our_services')}
          </p>
          <div className="grid grid-cols-1 gap-4 mt-5 rounded-lg laptop:grid-cols-4 small:grid-cols-2 tablet:gap-5 ">
            {plans?.map((plan) => (
              <CardItem
                icon={plan.icon}
                description={plan.description}
                name={plan.name}
                key={plan.id}
                is_on_premise={plan.is_on_premise}
                slug={plan.slug}
                url={plan.url}
              />
            ))}
          </div>
        </div>
      </LandingContainer>
    </div>
  )
}

interface ICardItem {
  icon: string
  name: string
  description: string
  is_on_premise: boolean
  slug: string
  url: any
}

const CardItem: React.FC<ICardItem> = ({
  icon,
  name,
  is_on_premise,
  slug,
  url,
}) => {
  // const {t} = useTranslation(NAMESPACE_LANDING_PAGE)
  return (
    <div
      style={{
        backgroundImage: `url(${ImageService.src})`,
      }}
      className="h-full transition-all bg-no-repeat bg-cover rounded-2xl hover:scale-105 hover:z-10 "
    >
      <Link
        href={is_on_premise ? `${ROUTE_SERVICES}/${slug}` : url}
        target={is_on_premise ? '_self' : '_blank'}
      >
        <div className="flex flex-col justify-between h-[300px] cursor-pointer">
          <div className="bg-white laptop:w-14 laptop:h-14 tablet:w-20 tablet:h-20 small:w-14 small:h-14 little:w-20 little:h-20 w-14 h-14 rounded-2xl">
            <img
              className="w-9 h-9  laptop:m-[10px] tablet:m-5  small:m-[10px] little:m-5 m-[10px]"
              src={icon}
              alt=""
            />
          </div>
          <div className="inline-block cursor-pointer p-4 text-[#0065E5] text-xl font-bold">
            {name}
          </div>
        </div>
      </Link>
    </div>
  )
}

export default Services
