import { API_CONTACT_US } from '@/api/apiRoutes'
import api from '@/lib/api'

interface IParams {
  full_name: string
  phone_number: string
}

export const contactUs = async ({ full_name, phone_number }: IParams) => {
  try {
    const { data } = await api.post(API_CONTACT_US, {
      full_name,
      phone_number,
    })

    return data
  } catch (err) {
    return Promise.reject(err)
  }
}
