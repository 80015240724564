import { useRouter } from 'next/router'
import { NextSeo } from 'next-seo'
import { MetaTag, OpenGraph, OpenGraphMedia } from 'next-seo/lib/types'
import { ReactNode } from 'react'

import { APP_NAME, SITE_URL } from '@/res/consts'

interface Props {
  seoTitle?: string
  seoDescription?: string
  seoTitleTemplate?: string
  seoOGType?: 'website' | 'article'
  seoOGDate?: string
  seoOGImage?: OpenGraphMedia
  children: ReactNode
  additionalMetaTags?: MetaTag[]
}

const BaseLayout: React.FC<Props> = ({
  children,
  seoTitle,
  seoDescription,
  seoTitleTemplate,
  seoOGType = 'website',
  seoOGDate,
  seoOGImage,
  additionalMetaTags,
}) => {
  const router = useRouter()

  const OGConfig: OpenGraph = {}

  OGConfig.type = seoOGType
  OGConfig.url = SITE_URL + router.asPath
  OGConfig.site_name = APP_NAME
  OGConfig.title = seoTitle
  OGConfig.description = seoDescription

  if (seoOGDate) {
    OGConfig.article = {}
    OGConfig.article.publishedTime = seoOGDate
  }

  if (seoOGImage) {
    OGConfig.images = [seoOGImage]
  }
  return (
    <>
      <NextSeo
        title={seoTitle}
        description={seoDescription}
        titleTemplate={seoTitleTemplate || `%s | ${APP_NAME}`}
        canonical={router.asPath}
        openGraph={OGConfig}
        additionalMetaTags={additionalMetaTags}
      />
      {children}
    </>
  )
}

export default BaseLayout
