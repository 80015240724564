import useTranslation from 'next-translate/useTranslation'
import React, { FormEvent, useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import { toast } from 'react-toastify'

import { contactUs } from '@/api/landing/contactUs'
import { IconInfoCircle } from '@/res/icons'
import { ImageContactUs } from '@/res/images'
import { NAMESPACE_LANDING_PAGE } from '@/res/namespaces'

import LandingContainer from '../LandingContainer'

const Request = () => {
  const { t, lang } = useTranslation(NAMESPACE_LANDING_PAGE)
  const notifySucces = () => toast.success(t('requests.toast_succes'))
  const notifyError = () => toast.error(t('requests.invalid_phone_number'))
  const [isSending, setIsSending] = useState(false)
  const [country, setCountry] = useState<undefined | string>(undefined)
  const [name, setName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  // const isRussian = lang === 'ru'

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    try {
      setIsSending(true)
      if (phoneNumber.length !== 12) {
        notifyError()
        return
      }
      await contactUs({ full_name: name, phone_number: `+${phoneNumber}` })
      setName('')
      setPhoneNumber('')
      notifySucces()
    } catch {
      notifyError()
      return
    } finally {
      setIsSending(false)
    }
  }

  return (
    <div id="request" className="bg-[#428BF9] laptop:px-0 small:px-16 px-0  ">
      <LandingContainer>
        <div className="flex laptop:flex-row flex-col justify-between ">
          <div>
            <div>
              <p className="tablet:text-6xl uppercase small:text-2xl text-xl font-bold pb-4 text-white mt-14">
                {t('requests.submit_request')}
              </p>
              <span className="text-white text-[15px]  montserrat font-medium leading-[24px] block pb-2">
                {t('requests.we_will_contact')}
              </span>
            </div>
            <div className="flex justify-start  small:pb-0 pb-10 small:px-0 px-5  w-full">
              <form onSubmit={onSubmit} className="mt-4 w-full">
                <div className="flex small:flex-row flex-col gap-2 tablet:space-y-0 space-y-4  tablet:mb-6 mb-3 ">
                  <input
                    type="name"
                    value={name}
                    placeholder={t('requests.input_name')}
                    className="bg-white rounded-[10px] tablet:mt-0 mt-4  outline-secondary small:w-1/2 py-3 px-3 text-base font-medium"
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                  <PhoneInput
                    specialLabel=""
                    country={country}
                    onFocus={() => {
                      setCountry('uz')
                    }}
                    alwaysDefaultMask
                    inputProps={{
                      placeholder: '+998 --  ---  --  --',
                      required: true,
                    }}
                    value={phoneNumber}
                    onChange={(value) => {
                      setPhoneNumber(value)
                    }}
                    defaultMask=".. ... - .. - .."
                    // inputClass="bg-white rounded-[10px] outline-secondary  py-3 px-3 text-base font-medium"
                    containerClass="bg-white rounded-[10px] tablet:mt-0 mt-4  outline-secondary small:w-1/2 w-full py-3 px-3 text-base font-medium"
                  />
                </div>
                <div className="flex small:justify-end border-b-[1px] pb-5 ">
                  <button
                    type="submit"
                    className="  bg-[#0065E5] tablet:mt-0 mt-4  px-3 py-3 small:w-1/2 w-full 
                  text-white inline-block rounded-[10px] cursor-pointer transition-all ease-in-out"
                  >
                    {isSending
                      ? t('requests.request_sending')
                      : t('requests.request_button')}
                  </button>
                </div>
              </form>
            </div>
            <div className="flex small:justify-between justify-center small:px-0 px-5 small:flex-row flex-col tablet:space-y-0 space-y-4  tablet:mb-6 mb-3  ">
              <div className="mt-5 flex  justify-start gap-5 servises_block w-full ">
                <div className="servises flex items-start gap-5 pr-3">
                  <img src={IconInfoCircle.src} alt="" />
                  <p className="text-white text-[16px] leading-6  font-medium small:w-[185px] w-full">
                    {t('requests.are_you_interested')}
                  </p>
                  <div className="bg-white w-[328px] rounded-md pt-2 px-4 servises_other ">
                    <h1 className="text-[20px] font-bold pb-1">
                      {t('requests.other_cloud_services')}
                    </h1>
                    <p className="font-medium text-[16px] pb-2">1. VDS</p>
                    <p className="font-medium text-[16px] pb-2">
                      2. Colocation
                    </p>
                    <p className="font-medium text-[16px] pb-2">3. VDI</p>
                    <p className="font-medium text-[16px]">
                      4. {t('requests.videoconferencing')}
                    </p>
                    <div className="triangle-top"></div>
                  </div>
                </div>
              </div>
              <div className="pb-12 w-full">
                <button
                  type="button"
                  className="  bg-[#0065E5] tablet:mt-5  px-1 py-3 w-full 
                  text-white inline-block rounded-[10px] cursor-pointer transition-all ease-in-out"
                >
                  {lang === 'ru' ? (
                    <a href="https://uztelecom.uz/ru/biznesu/onlayn-zayavka">
                      {t('requests.go_to_registr')}
                    </a>
                  ) : lang === 'uz' ? (
                    <a href="https://uztelecom.uz/uz/biznesga/onlayn-ariza-2">
                      {t('requests.go_to_registr')}
                    </a>
                  ) : (
                    <a href="https://uztelecom.uz/en/for-business/online-application-2">
                      {t('requests.go_to_registr')}
                    </a>
                  )}
                </button>
              </div>
            </div>
          </div>
          <div className="small:block hidden">
            <img className="mx-auto" src={ImageContactUs.src} alt="" />
          </div>
        </div>
      </LandingContainer>
    </div>
  )
}

export default Request
