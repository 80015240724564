import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import useTranslation from 'next-translate/useTranslation'
import React from 'react'

import { IconMonitor, IconSmartphone } from '@/res/icons'
import {
  ImageMyuztelecom,
  ImageTelecomTv,
  ImageUzcloud,
  ImageUztelecomDisk,
  ImageUztelecomUz,
  ImageWifiuzcloud,
} from '@/res/images'
import { NAMESPACE_LANDING_PAGE } from '@/res/namespaces'

import LandingContainer from '../LandingContainer'

const Projects = () => {
  const { t } = useTranslation(NAMESPACE_LANDING_PAGE)

  return (
    <div
      id="projects"
      className="bg-[#F6F7F8] tablet:px-0 small:px-16 px-0 pb-20 "
    >
      <LandingContainer>
        <p
          className="small:pt-[100px] pt-[50px] desktop:text-[44px] desktop:leading-[48px] tablet:text-[38px] tablet:leading-[44px] small:text-[34px] small:leading-[40px] \
        text-[30px] leading-[36px] font-bold text-black/80 uppercase"
        >
          {t('projects.our_projects')}
        </p>
        <div className="grid justify-center grid-cols-2 gap-5 pt-5 text-black/80 tablet:grid-cols-6 small:grid-rows-2 small:grid-cols-3 justify-items-center">
          <div
            style={{
              backgroundImage: `url(${ImageMyuztelecom.src})`,
            }}
            className="w-full h-full col-span-2 row-span-2 bg-no-repeat bg-cover rounded-2xl"
          >
            <div className="flex flex-col justify-between small:h-[413px] h-[341px]">
              <div className="laptop:w-[74px] laptop:h-[74px]  tablet:w-16 tablet:h-16 little:w-[74px] little:h-[74px]  w-16 h-16  bg-white rounded-2xl">
                <IconSmartphone
                  className={`laptop:p-5 tablet:p-4 little:p-5 p-4 laptop:w-[74px] laptop:h-[74px] tablet:w-16 tablet:h-16 little:w-[74px] little:h-[74px] w-16 h-16 text-white   transition-colors  `}
                />
              </div>
              <div className="small:p-6 p-[20px] small:w-[211px] ">
                <span className="uppercase text-[32px] leading-10 montserrat text-white font-black">
                  My uztelecom
                </span>
              </div>
            </div>
          </div>
          <div
            style={{
              backgroundImage: `url(${ImageUztelecomUz.src})`,
            }}
            className="w-full bg-no-repeat bg-cover rounded-2xl "
          >
            <div className="flex flex-col  justify-between h-[197px]  ">
              <div className="bg-white w-9 h-9 rounded-xl">
                <IconMonitor
                  className={`p-[10px] text-white w-9 h-9  transition-colors  `}
                />
              </div>
              <div className="small:p-[12px] p-2 small:w-[211px] ">
                <span className="uppercase text-[24px] leading-7  montserrat text-white font-black">
                  utc.uz
                </span>
              </div>
            </div>
          </div>
          <div
            style={{
              backgroundImage: `url(${ImageUztelecomDisk.src})`,
            }}
            className="bg-no-repeat  bg-cover tablet:row-span-2 tablet:h-[413px] h-[197px] w-full tablet:col-span-2 rounded-2xl"
          >
            <div className="flex flex-col justify-between tablet:h-[413px] h-[197px] ">
              <div className="tablet:w-[74px] tablet:h-[74px]  w-9 h-9  bg-white tablet:rounded-2xl rounded-xl ">
                <IconSmartphone
                  className={`tablet:p-5 p-[2px] tablet:w-[74px] tablet:h-[74px]   w-9 h-9 text-white   transition-colors`}
                />
              </div>
              <div className="tablet:p-6 p-2 tablet:max-w-max-[211px]  max-w-max-[197px] ">
                <span className="uppercase tablet:text-[32px]  leading-7   montserrat text-[24px] text-white font-black">
                  udisk.uz
                </span>
              </div>
            </div>
          </div>
          <div
            style={{
              backgroundImage: `url(${ImageTelecomTv.src})`,
            }}
            className="w-full col-span-2 row-span-2 bg-no-repeat bg-cover rounded-2xl small:row-span-1 small:col-span-1"
          >
            <div className="flex flex-col justify-between small:h-[197px] h-[341px] ">
              <div className=" small:w-9 small:h-9  little:w-[80px] little:h-[80px]  w-16 h-16  bg-white small:rounded-xl rounded-2xl  ">
                <IconMonitor
                  className={`small:p-[10px] little:p-8 p-6 text-white little:w-[80px] little:h-[80px] w-16 h-16 transition-colors  `}
                />
              </div>
              <div className="p-[12px]  small:max-w-max-[197px] ">
                <span className="uppercase small:text-[24px]   leading-7 montserrat text-[32px] text-white font-black">
                  telecom tv
                </span>
              </div>
            </div>
          </div>
          <div
            style={{
              backgroundImage: `url(${ImageWifiuzcloud.src})`,
            }}
            className="bg-no-repeat  bg-cover rounded-2xl  tablet:h-[200px] w-full small:h-[410px] teblet:row-span-1  tablet:col-span-1 small:row-span-2 small:col-span-2 h-[197px] "
          >
            <div className="flex flex-col justify-between tablet:h-full small:h-[413px] h-[197px] ">
              <div className=" tablet:w-9 tablet:h-9  small:w-[74px] small:h-[74px] w-9 h-9 bg-white tablet:rounded-xl small:rounded-2xl rounded-lg   ">
                <IconMonitor
                  className={`tablet:p-[10px] small:p-7 p-[10px] text-white tablet:w-9 tablet:h-9  small:w-[74px] small:h-[74px] w-9 h-9  transition-colors   `}
                />
              </div>
              <div className="small:p-[12px] p-2 small:w-[177px] ">
                <span className="uppercase laptop:text-[24px] leading-[24px] montserrat tablet:text-[20px] small:text-[32px] text-[24px]  text-white font-black">
                  Uzcloud wifi
                </span>
              </div>
            </div>
          </div>
          <div
            style={{
              backgroundImage: `url(${ImageUzcloud.src})`,
            }}
            className="bg-no-repeat  bg-cover h-[197px]  w-full  rounded-2xl  "
          >
            <div className="flex flex-col justify-between h-[197px]  ">
              <div className="bg-white w-9 h-9 rounded-xl ">
                <IconMonitor
                  className={`p-[10px] text-white w-9 h-9  transition-colors  `}
                />
              </div>
              <div className="small:p-[12px] p-2 small:max-w-max-[197px] ">
                <span className=" uppercase laptop:text-[24px]  montserrat tablet:text-[20px] text-[24px]  text-white font-black">
                  Uzdc
                </span>
              </div>
            </div>
          </div>
        </div>
      </LandingContainer>
    </div>
  )
}

export default Projects
