import useTranslation from 'next-translate/useTranslation'
import React, { useState } from 'react'

import {
  IconAdobeillus,
  IconBack,
  IconBitbucket,
  IconCodepen,
  IconCodevs,
  IconCplus,
  IconCsh,
  IconCss,
  IconDesign,
  IconDevops,
  IconDocker,
  IconDotnet,
  IconFigma,
  IconFlutter,
  IconFront,
  IconGithub,
  IconGitlab,
  IconGo,
  IconGraphql,
  IconHtml,
  IconInfrast,
  IconIos,
  IconJava,
  IconJbkotlin,
  IconJira,
  IconJquery,
  IconJs,
  IconLinux,
  IconMobile,
  IconNextjs,
  IconNodejs,
  IconNpm,
  IconPhshop,
  IconPython,
  IconReact,
  IconRedux,
  IconScetch,
  IconSlack,
  IconSvetle,
  IconSwift,
  IconVue,
  IconYii,
} from '@/res/icons'
import {
  ImageToolblue,
  ImageToolbrown,
  ImageTooldarkblue,
  ImageToolgreen,
  ImageToolred,
  ImageToolviolet,
} from '@/res/images'
import { NAMESPACE_LANDING_PAGE } from '@/res/namespaces'

import LandingContainer from '../LandingContainer'

const Tools = () => {
  const { t } = useTranslation(NAMESPACE_LANDING_PAGE)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedType, setSelectedType] = useState('')
  const tools = [
    { type: ['frontend', 'mobile', 'backend'], Icon: IconReact },
    { type: ['backend'], Icon: IconYii },
    { type: ['backend'], Icon: IconCodepen },
    { type: ['mobile'], Icon: IconSwift },
    { type: ['design'], Icon: IconPhshop },
    { type: ['mobile'], Icon: IconJbkotlin },
    {
      type: ['frontend', 'backend', 'mobile', 'infrasturcture', 'devops'],
      Icon: IconGithub,
    },
    { type: ['frontend'], Icon: IconSvetle },
    { type: ['frontend'], Icon: IconJquery },
    { type: ['frontend'], Icon: IconCss },
    {
      type: [
        'frontend',
        'backend',
        'mobile',
        'infrasturcture',
        'devops',
        'design',
      ],
      Icon: IconJira,
    },
    { type: ['mobile'], Icon: IconFlutter },
    { type: ['infrasturcture', 'frontend'], Icon: IconNodejs },
    {
      type: ['frontend', 'backend', 'mobile', 'infrasturcture', 'devops'],
      Icon: IconCodevs,
    },
    { type: ['frontend', 'backend'], Icon: IconGraphql },
    { type: ['backend', 'mobile', 'frontend'], Icon: IconNpm },
    { type: ['design'], Icon: IconScetch },
    {
      type: ['frontend', 'backend', 'mobile', 'infrasturcture', 'devops'],
      Icon: IconGitlab,
    },
    { type: ['design'], Icon: IconAdobeillus },
    { type: ['backend', 'mobile'], Icon: IconJava },
    { type: ['backend', 'frontend'], Icon: IconJs },
    { type: ['frontend'], Icon: IconHtml },
    {
      type: ['frontend', 'backend', 'mobile', 'infrasturcture', 'devops'],
      Icon: IconBitbucket,
    },
    { type: ['backend'], Icon: IconGo },
    { type: ['frontend'], Icon: IconVue },
    { type: ['design', 'mobile', 'frontend'], Icon: IconFigma },
    { type: ['backend'], Icon: IconCplus },
    { type: ['frontend'], Icon: IconNextjs },
    { type: ['infrasturcture'], Icon: IconLinux },
    { type: ['frontend'], Icon: IconRedux },
    { type: ['mobile'], Icon: IconIos },
    {
      type: [
        'frontend',
        'backend',
        'mobile',
        'infrasturcture',
        'devops',
        'design',
      ],
      Icon: IconSlack,
    },
    {
      type: ['backend', 'mobile', 'frontend', 'infrasturcture', 'devops'],
      Icon: IconDocker,
    },
    { type: ['backend'], Icon: IconDotnet },
    { type: ['backend'], Icon: IconCsh },
    { type: ['backend'], Icon: IconPython },
  ]

  const menu = [
    {
      bg: ImageToolgreen,
      Icon: IconFront,
      color: 'text-[#228968]',
      text: t('tools.frontend'),
      type: 'frontend',
      bgcolor: 'bg-[#228968]',
    },
    {
      bg: ImageTooldarkblue,
      Icon: IconBack,
      color: 'text-[#0065E5]',
      text: t('tools.backend'),
      type: 'backend',
      bgcolor: 'bg-[#0065E5]',
    },
    {
      bg: ImageToolred,
      Icon: IconDevops,
      color: 'text-[#C60025]',
      text: t('tools.devops'),
      type: 'devops',
      bgcolor: 'bg-[#C60025]',
    },
    {
      bg: ImageToolbrown,
      Icon: IconDesign,
      color: 'text-[#D88427]',
      text: t('tools.product_design'),
      type: 'design',
      bgcolor: 'bg-[#D88427]',
    },
    {
      bg: ImageToolblue,
      Icon: IconInfrast,
      color: 'text-[#29A9E8]',
      text: t('tools.infrasturcture'),
      type: 'infrasturcture',
      bgcolor: 'bg-[#29A9E8]',
    },
    {
      bg: ImageToolviolet,
      Icon: IconMobile,
      color: 'text-[#8A5FFB]',
      text: t('tools.mobile'),
      type: 'mobile',
      bgcolor: 'bg-[#8A5FFB]',
    },
  ]

  return (
    <div id="tools" className="bg-[#F6F7F8] tablet:px-0 small:px-16 px-0 ">
      <LandingContainer>
        <div>
          <p className="small:pt-[100px] pt-[50px] desktop:text-[44px] uppercase desktop:leading-[48px] tablet:text-[38px] tablet:leading-[44px] small:text-[34px] small:leading-[40px] text-[30px] leading-[36px] font-bold text-black/80">
            {t('tools.tools')}
          </p>
          <div className="pt-5 text-black/80 grid desktop:grid-cols-6 small:grid-cols-3 grid-cols-2 gap-5">
            {menu.map(({ Icon, text, bg, type, color, bgcolor }) => (
              <button
                key={text}
                style={{
                  backgroundImage: `url(${bg.src})`,
                }}
                className="bg-no-repeat  bg-cover h-full rounded-2xl hover:scale-105 hover:z-10 transition-all"
                onClick={() => {
                  if (selectedType !== type) {
                    setSelectedType(type)
                  } else {
                    setSelectedType('')
                  }
                }}
              >
                <div className="flex flex-col justify-between small:h-[197px] h-[180px] ">
                  <div className="desktop:w-12 desktop:h-12 tablets:w-[75px] tablets:h-[75px] tablet:w-14 tablet:h-14 little:w-12 little:h-12  w-11 h-11 bg-white rounded-2xl ">
                    <Icon
                      className={`text-white desktop:w-12 desktop:h-12 tablets:w-[75px] tablets:h-[75px] tablet:w-14 tablet:h-14 little:w-12 little:h-12  w-11 h-11   desktop:p-3 tablets:p-6 tablet:p-4  small:p-3  little:p-[11px] p-[9px] rounded-2xl ${
                        selectedType === type
                          ? `{ ${bgcolor} 'bg-[#0065E5] text-white'}`
                          : color
                      }`}
                    />
                  </div>
                  <div className="p-3 small:max-w-max-[211px] text-start">
                    <span
                      className={` ${color} small:text-[20px] text-[16px] small:leading-7 leading-6 font-bold`}
                    >
                      {text}
                    </span>
                  </div>
                </div>
              </button>
            ))}
          </div>
          <div className="grid desktop:grid-cols-12  small:grid-cols-6 grid-cols-4 gap-5 pt-6">
            {tools.map(({ type, Icon }) => {
              return (
                <div
                  key={Icon}
                  className={`${
                    type.includes(selectedType)
                      ? 'border-4  rounded-xl border-blue-800'
                      : 'border-4  rounded-xl border-transparent'
                  }`}
                >
                  <div className="bg-white  h-[75px] items-center flex justify-center rounded-2xl">
                    <Icon />
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </LandingContainer>
    </div>
  )
}

export default Tools
