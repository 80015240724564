import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'

import { CONTACT_NUMBER } from '@/res/consts'
import { IconLogoFooter, IconPhone } from '@/res/icons'
import { NAMESPACE_LANDING_PAGE } from '@/res/namespaces'

import LandingContainer from '../LandingContainer'

const Footer = () => {
  const { t } = useTranslation(NAMESPACE_LANDING_PAGE)

  return (
    <div className="pt-6 pb-8 bg-[#5C9DFF] tablet:px-0 px-[52px]">
      <LandingContainer>
        <div className="flex flex-col justify-between text-center laptop:flex-row laptop:items-center ">
          <ul className="flex flex-col items-start my-4 laptop:flex-row laptop:items-center small:space-y-0 gap-7">
            <li>
              <a href="https://uztelecom.uz/" target="_blank" rel="noreferrer">
                <IconLogoFooter />
              </a>
            </li>
            <li className="text-[16px]  text-white font-medium uppercase">
              <Link href="#services">{t('navbar.services')}</Link>
            </li>
            <li className="text-[16px]  text-white font-medium uppercase">
              <Link href="#about">{t('navbar.about_us')}</Link>
            </li>
            <li className="text-[16px]  text-white font-medium uppercase">
              <Link href="#tools">{t('navbar.tools')}</Link>
            </li>
            <li className="text-[16px]  text-white font-medium uppercase">
              <Link href="#request">{t('navbar.contact_with_us')}</Link>
            </li>
            <li className="text-[16px]  text-white font-medium uppercase">
              <a
                href="https://t.me/uzdc_feedback_bot"
                target="_blank"
                rel="noreferrer"
              >
                {t('navbar.feedback')}
              </a>
            </li>
          </ul>
          <div className="flex items-center gap-6 tablet:border-t-0 border-t-[2px] tablet:pt-0 pt-6 ">
            <div className="flex items-center gap-x-2 ">
              <img src={IconPhone.src} className="w-5 h-5" alt="phone" />
              <p className="text-white text-[16px] font-bold my-2">
                <a href={`tel:${CONTACT_NUMBER}`}>{CONTACT_NUMBER}</a>
              </p>
            </div>
            <div className="font-semibold text-[16px] text-white">
              <a href="mailto:info@infosystems.uz" data-ajax="false">
                info@infosystems.uz
              </a>
            </div>
          </div>
        </div>

        <div className="tablet:py-4 tablet:pt-10  tablet:border-t-[2px] tablet:flex-row flex-col-reverse flex justify-between tablet:items-center">
          <p className="tablet:text-[16px] text-[14px] tablet:leading-7  montserrat leading-[22px] text-white font-medium">
            © {new Date().getFullYear()} {t('navbar.branch_telecom_data')}
          </p>
          <ul className="flex my-5 space-x-6 tablet:my-0">
            <li className="cursor-pointer">
              <a
                href="https://www.facebook.com/uztelecom"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.75 1.75H2.25C1.97344 1.75 1.75 1.97344 1.75 2.25V13.75C1.75 14.0266 1.97344 14.25 2.25 14.25H13.75C14.0266 14.25 14.25 14.0266 14.25 13.75V2.25C14.25 1.97344 14.0266 1.75 13.75 1.75ZM12.3062 5.39844H11.3078C10.525 5.39844 10.3734 5.77031 10.3734 6.31719V7.52187H12.2422L11.9984 9.40781H10.3734V14.25H8.425V9.40938H6.79531V7.52187H8.425V6.13125C8.425 4.51719 9.41094 3.6375 10.8516 3.6375C11.5422 3.6375 12.1344 3.68906 12.3078 3.7125V5.39844H12.3062Z"
                    fill="white"
                    fillOpacity=""
                  />
                </svg>
              </a>
            </li>
            <li className="cursor-pointer">
              <a
                href="https://www.instagram.com/uztelecomuz/"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  fill="black"
                  fillOpacity="0.32"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18px"
                  height="18px"
                >
                  <path
                    d="M 8 3 C 5.239 3 3 5.239 3 8 L 3 16 C 3 18.761 5.239 21 8 21 L 16 21 C 18.761 21 21 18.761 21 16 L 21 8 C 21 5.239 18.761 3 16 3 L 8 3 z M 18 5 C 18.552 5 19 5.448 19 6 C 19 6.552 18.552 7 18 7 C 17.448 7 17 6.552 17 6 C 17 5.448 17.448 5 18 5 z M 12 7 C 14.761 7 17 9.239 17 12 C 17 14.761 14.761 17 12 17 C 9.239 17 7 14.761 7 12 C 7 9.239 9.239 7 12 7 z M 12 9 A 3 3 0 0 0 9 12 A 3 3 0 0 0 12 15 A 3 3 0 0 0 15 12 A 3 3 0 0 0 12 9 z"
                    fill="white"
                    fillOpacity="1"
                  />
                </svg>
              </a>
            </li>
            <li className="cursor-pointer">
              <a
                href="https://www.youtube.com/channel/UCD-83B-ECCapTYGxUV5V5oQ"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.7078 4.62656C14.6281 4.32975 14.4719 4.05907 14.2547 3.84162C14.0375 3.62417 13.767 3.46757 13.4703 3.3875C12.3781 3.09375 8 3.09375 8 3.09375C8 3.09375 3.62187 3.09375 2.52969 3.38594C2.23284 3.46575 1.96223 3.62226 1.74501 3.83975C1.52779 4.05724 1.37162 4.32806 1.29219 4.625C1 5.71875 1 8 1 8C1 8 1 10.2813 1.29219 11.3734C1.45312 11.9766 1.92812 12.4516 2.52969 12.6125C3.62187 12.9062 8 12.9062 8 12.9062C8 12.9062 12.3781 12.9062 13.4703 12.6125C14.0734 12.4516 14.5469 11.9766 14.7078 11.3734C15 10.2813 15 8 15 8C15 8 15 5.71875 14.7078 4.62656ZM6.60938 10.0938V5.90625L10.2344 7.98438L6.60938 10.0938Z"
                    fill="white"
                    fillOpacity="1"
                  />
                </svg>
              </a>
            </li>
            <li className="cursor-pointer">
              <a
                href="https://t.me/uztelecomuz"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_129_1790)">
                    <path
                      d="M14.7011 1.41966L0.525852 6.91423C-0.0445851 7.1701 -0.237523 7.68251 0.387977 7.9606L4.02454 9.12226L12.8173 3.66007C13.2974 3.31716 13.7889 3.4086 13.3659 3.78582L5.81416 10.6588L5.57695 13.5674C5.79667 14.0165 6.19898 14.0186 6.4556 13.7954L8.54492 11.8082L12.1232 14.5016C12.9543 14.9961 13.4065 14.677 13.5853 13.7705L15.9324 2.59957C16.176 1.48379 15.7605 0.992164 14.7011 1.41966Z"
                      fill="white"
                      fillOpacity="1"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_129_1790">
                      <rect width="16" height="16" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </a>
            </li>
          </ul>
        </div>
      </LandingContainer>
    </div>
  )
}

export default Footer
