import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'
import React from 'react'
import Slider from 'react-slick'

import {
  IconExpress24,
  IconKadastr,
  IconKundalik,
  IconMinFin,
  IconMyTaxi,
  IconUniversalBank,
} from '@/res/icons'
import { NAMESPACE_LANDING_PAGE } from '@/res/namespaces'

import LandingContainer from '../LandingContainer'

const settingss = {
  dots: false,
  infinite: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
  pauseOnHover: true,
  arrows: false,
  responsive: [
    {
      breakpoint: 980,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 580,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
}

const Clients = () => {
  const { t } = useTranslation(NAMESPACE_LANDING_PAGE)

  const temps = [
    {
      icon: IconMinFin,
      isSvg: false,
      link: 'https://www.mf.uz/ru/',
    },

    {
      icon: IconKadastr,
      isSvg: false,
      link: 'https://kadastr.uz/uz',
    },
    {
      icon: IconUniversalBank,
      isSvg: false,
      link: 'https://universalbank.uz/private',
    },
    {
      icon: IconKundalik,
      isSvg: false,
      link: 'https://kundalik.com/',
    },

    {
      Icon: IconExpress24,
      isSvg: true,
      link: 'https://express24.uz/',
    },
    {
      Icon: IconMyTaxi,
      isSvg: true,
      link: 'https://www.mytaxi.uz/',
    },
  ]

  return (
    <div className="bg-[#F6F7F8] tablet:px-0 small:px-16 px-0 ">
      <LandingContainer>
        <p
          className="small:pt-[100px] pt-[50px] desktop:text-[44px] desktop:leading-[48px] tablet:text-[38px] tablet:leading-[44px] small:text-[34px] small:leading-[40px] \
        text-[30px] leading-[36px] font-bold text-black/80  uppercase"
        >
          {t('clients.clients')}
        </p>
        <div className="mt-5 rounded-3xl bg-white p-5">
          <Slider className="slick-tracks" {...settingss}>
            {temps.map(({ icon, isSvg, Icon, link }, index) => (
              <CardItem
                icon={icon}
                isSvg={isSvg}
                Icon={Icon}
                link={link}
                key={index}
              />
            ))}
          </Slider>
        </div>
      </LandingContainer>
    </div>
  )
}

interface ICardItem {
  icon: any
  Icon: any
  isSvg: boolean
  link: string
}

const CardItem: React.FC<ICardItem> = ({ icon, Icon, isSvg, link }) => {
  return (
    <Link href={link}>
      <div className="flex justify-center items-center cursor-pointer">
        {isSvg ? <Icon /> : <img className="w-40 " src={icon.src} alt="" />}
      </div>
    </Link>
  )
}

export default Clients
